<template>
    <div class="moneyFlow">
        <div class="searck_box">
            <div class="search_left">
                <p>
                    <Department
                        ref="department"
                        :departmentWidth="170"
                        :adminWidth="170"
                        :depSearch="btnP.depSearch"
                        :searchUser="btnP.userSearch"
                        @searchData="searchData"
                    ></Department>
                </p>
                <p>
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 180px"
                        placeholder="请输入客户名称"
                        v-model="searchVal.nameVal"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="
                            (currentPage = 1), (pagesize = 20), getTableData()
                        "
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
                <!-- <p>
                    <span v-show="btnP.depSearch">部门</span>
                    <el-select
                        v-show="btnP.depSearch"
                        v-model="searchVal.dempVal"
                        size="small"
                        style="width: 170px"
                        clearable
                        placeholder="请选择部门"
                        @change="
                            (currentPage = 1),
                                (pagesize = 20),
                                changeDepartmentId()
                        "
                    >
                        <el-option
                            v-for="item in dempData"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p>
                <p>
                    <span v-show="btnP.userSearch">成员</span>
                    <el-select
                        v-show="btnP.userSearch"
                        v-model="searchVal.adminVal"
                        size="small"
                        style="width: 170px"
                        clearable
                        placeholder="请选择成员"
                        @change="
                            (currentPage = 1), (pagesize = 20), getTableData()
                        "
                    >
                        <el-option
                            v-for="item in userData"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p> -->
                <p>
                    <span>时间选择</span>
                    <el-date-picker
                        size="small"
                        v-model="searchVal.createDate"
                        type="month"
                        placeholder="请选择"
                        value-format="yyyy-MM"
                        clearable
                        @change="
                            (currentPage = 1), (pagesize = 20), getTableData()
                        "
                    >
                    </el-date-picker>
                </p>
            </div>
            <div class="search_right">
                <p><img src="@/assets/jine.png" alt="" />月回款金额</p>
                <p>{{ tableBrief }}</p>
            </div>
        </div>
        <div class="table_title">
            <p
                v-if="btnP.transaction"
                :class="isType == 2 ? 'isColor' : ''"
                @click="
                    (isType = 2),
                        (currentPage = 1),
                        (pagesize = 20),
                        getTableData()
                "
            >
                交易时间
            </p>
            <p
                v-if="btnP.writeOff"
                :class="isType == 1 ? 'isColor' : ''"
                @click="
                    (isType = 1),
                        (currentPage = 1),
                        (pagesize = 20),
                        getTableData()
                "
            >
                核销时间
            </p>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column
                label="核销时间"
                width="93"
                show-overflow-tooltip
                v-if="isType == 1"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.createTime
                            ? scope.row.createTime.slice(0, 10)
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="交易时间"
                width="93"
                show-overflow-tooltip
                v-if="isType == 2"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.receivedTime
                            ? scope.row.receivedTime.slice(0, 10)
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="到账金额" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.receivedAmount || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="回款方式" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.paymentWay == 1
                            ? '扫码支付'
                            : scope.row.paymentWay == 2
                            ? '对公转账'
                            : '- -'
                    }}</span></template
                >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="客户名称" width="239" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.companyName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="合同金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.contractAmount || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="所属人"
                show-overflow-tooltip
                prop="adminName"
                width="55"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="所属部门"
                show-overflow-tooltip
                prop="departmentName"
                width="80"
            >
                <template slot-scope="scope">
                    <el-tooltip placement="top">
                        <div slot="content">
                            <p
                                style="line-height: 2"
                                v-for="item in scope.row.departmentAdminDOList"
                                :key="item.id"
                            >
                                {{ item.departmentName }}
                            </p>
                        </div>
                        <div>
                            {{ returnName(scope.row) }}
                        </div>
                    </el-tooltip>
                </template>
            </el-table-column>

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import { getWater, waterBrief } from '@/api/contr/money.js';
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
import Department from '../../../components/Department';
export default {
    data() {
        return {
            searchVal: {
                createDate: this.getDate(),
            },
            btnP: {},
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            dempData: [],
            userData: [],
            isType: 2,
            tableBrief: 0,
        };
    },
    components: {
        Department,
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        returnName(row) {
            var str = '';
            row.departmentAdminDOList.forEach((item) => {
                if (item.main == 1) {
                    str = item.departmentName;
                }
            });
            return str;
        },
        getDate() {
            var now = new Date();
            var year = now.getFullYear(); //得到年份
            var month = now.getMonth(); //得到月份
            //得到日期
            var hour = ' 00:00:00'; //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
            month = month + 1;
            month = month.toString().padStart(2, '0');
            var defaultDate = `${year}-${month}`; //
            return defaultDate;
        },
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            if (btn.userSearch && !btn.depSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            if (btn.depSearch) {
                this.getDempData();
            }
            if (!btn.transaction && btn.writeOff) {
                this.isType = 1;
            }
            if (btn.transaction) {
                this.isType = 2;
            }
            this.getTableData();
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
                this.$refs.department.getData(this.btnP, res.data);
            });
        },

        searchData(data) {
            this.pagesize = 20;
            this.pageNum = 1;
            this.searchVal.dempVal = data.departmentId;
            this.searchVal.adminVal = data.adminId;
            this.getTableData();
        },
        getTableData() {
            let data = {
                param: {
                    dateType: this.isType,
                    name: this.searchVal.nameVal,
                    dateParam: this.searchVal.createDate,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (!this.btnP.depSearch && this.btnP.userSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
                if (this.searchVal.adminVal) {
                    data.param.adminId = this.searchVal.adminVal;
                }
            }
            if (this.btnP.depSearch && !this.btnP.userSearch) {
                data.param.departmentId = this.searchVal.dempVal;
            }
            if (this.btnP.depSearch && this.btnP.userSearch) {
                data.param.departmentId = this.searchVal.dempVal;
                data.param.adminId = this.searchVal.adminVal;
            }
            if (!this.btnP.depSearch && !this.btnP.userSearch) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (this.searchVal.nameVal) {
                data.param.name = this.searchVal.nameVal;
            }
            this.waterBrief();
            getWater(data).then((res) => {
                console.log(res);
                this.tableData = res.data.list;
                this.total = res.data.total;
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },
        waterBrief() {
            let data = {
                param: {
                    dateType: this.isType,
                    name: this.searchVal.nameVal,
                    dateParam: this.searchVal.createDate,
                },
            };
            if (this.searchVal.name) {
                data.param.name = this.searchVal.name;
            }
            if (!this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }
            if (!this.btnP.userSearch) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }

            if (this.searchVal.dempVal) {
                data.param.departmentId = this.searchVal.dempVal;
            }
            if (this.searchVal.adminVal) {
                data.param.adminId = this.searchVal.adminVal;
            }
            waterBrief(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    if (res.data) {
                        this.tableBrief = res.data.pastAmount;
                    } else {
                        this.tableBrief = 0;
                    }
                } else {
                    this.tableBrief = 0;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.moneyFlow {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .searck_box {
        display: flex;
        border-radius: 4px;
        font-size: 12px;
        .search_left {
            display: flex;
            background: #fff;
            flex: 1.4;
            padding-top: 12px;
            flex-flow: row wrap;
            p {
                padding-bottom: 12px;
                white-space: nowrap;
            }
            span {
                font-weight: 600 !important;
            }
        }
        .search_right {
            background: #fff;
            height: 96px;
            margin-left: 12px;
            font-weight: 600;
            flex: 0.6;
            p {
                display: flex;
                align-items: center;
            }
            p:nth-child(1) {
                padding-left: 16px;
                padding-top: 16px;
                color: #333333;
                font-size: 13px;
                img {
                    margin-right: 8px;
                }
            }
            p:nth-child(2) {
                padding-left: 44px;
                padding-top: 18px;
                font-size: 16px;
                color: #333333;
            }
        }
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        button {
            background: #2370eb;
            border-color: #2370eb;
            border-radius: 2px;
            margin-right: 16px;
        }
    }
    .table_title {
        background: #fff;
        margin-top: 12px;
        display: flex;
        padding: 12px 0;
        padding-left: 37px;
        p {
            cursor: pointer;
            width: 64px;
            height: 26px;
            border-radius: 13px;
            border: 1px solid #f0f0f0;
            margin-right: 12px;
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            text-align: center;
        }
        .isColor {
            background: #e9f2ff;
            color: #2370eb;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
